<template>
  <div class="bg-white px-3 py-3">
    <b-row>
      <b-col>
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          v-model="form.name"
          :v="v.form.name"
          :isValidate="v.form.name.$error"
        />
      </b-col>
      <b-col>
        <label class="label"
          >Event Type<span class="text-error">*</span></label
        >
        <b-form-select v-model="form.event_type_id">
          <option
            v-for="(event, index) in eventList"
            :key="index"
            :value="event.id"
          >
            {{ event.name }}
          </option>
        </b-form-select>
      </b-col>
    </b-row>
    <div v-if="form.event_type_id === 1 || form.event_type_id === 5">
      <b-row>
        <b-col>
          <InputText
            textFloat="Sending date"
            placeholder="Sending date"
            type="number"
            name="round_day"
            v-model="form.round_day"
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 2);'"
            :v="v.form.round_day"
            :isValidate="v.form.round_day.$error"
          />
        </b-col>
        <b-col>
          <label class="label">Hours/Minutes</label>
          <div
            :class="[
              v.form.round_hours.$error
                ? 'border-error input-container'
                : 'input-container',
            ]"
          >
            <datetime
              type="time"
              class="date-picker"
              :input-style="styleDatetime"
              v-model="hourMinute"
              placeholder="HH:MM"
              format="HH:mm"
              value-zone="Asia/Bangkok"
              ref="automationHourMinute"
            >
            </datetime>
            <div
              :class="'icon-container text-right'"
              @click="$refs.automationHourMinute.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'pointer color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
          <div v-if="v.form.round_hours.$error">
            <span class="text-error">Please select.</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="form.event_type_id === 7">
      <b-col md="6">
        <label class="label">Hours/Minutes</label>
        <div
          :class="[
            v.form.round_hours.$error
              ? 'border-error input-container'
              : 'input-container',
          ]"
        >
          <datetime
            type="time"
            class="date-picker"
            :input-style="styleDatetime"
            v-model="hourMinute"
            placeholder="HH:MM"
            format="HH:mm"
            value-zone="Asia/Bangkok"
            ref="automationHourMinute"
          >
          </datetime>
          <div
            :class="'icon-container text-right'"
            @click="$refs.automationHourMinute.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.round_hours.$error">
          <span class="text-error">Please select.</span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="form.event_type_id == 3">
      <b-col md="6">
        <label class="label">Campaign<span class="text-error">*</span></label>
        <b-form-select
          v-model="form.campaign_id"
          :class="v.form.campaign_id.$error ? 'border-error' : ''"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Please Select</b-form-select-option
            >
          </template>
          <option
            v-for="(campagin, c) in campaignList"
            :key="c"
            :value="campagin.id"
          >
            {{ campagin.name }}
          </option>
        </b-form-select>
        <div v-if="v.form.campaign_id.$error">
          <span class="text-error">Please select Campagin.</span>
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row class="mt-3">
      <b-col>
        <label class="label"
          >Start Date (DD/MM/YYYY) <span class="text-error">*</span></label
        >
        <div
          :class="
            v.form.valid_from.$error
              ? 'border-error input-container'
              : 'input-container'
          "
        >
          <datetime
            type="datetime"
            :class="'date-picker mb-0'"
            :input-style="styleDatetime"
            v-model="form.valid_from"
            :min-datetime="dateFormat()"
            placeholder="DD/MM/YYYY (HH:MM)"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            ref="transferDateStart"
          >
          </datetime>
          <div
            :class="'icon-container text-right'"
            @click="$refs.transferDateStart.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.valid_from.$error">
          <span class="text-error">Please input start date.</span>
        </div>
      </b-col>
      <b-col>
        <label class="label"
          >End Date (DD/MM/YYYY) <span class="text-error">*</span></label
        >
        <div
          :class="[
            v.form.valid_to.$error
              ? 'border-error input-container'
              : 'input-container',
          ]"
        >
          <datetime
            type="datetime"
            :input-style="styleDatetime"
            v-model="form.valid_to"
            placeholder="DD/MM/YYYY (HH:MM)"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            ref="transferDateEnd"
            :min-datetime="form.valid_from"
            :class="'date-picker mb-0'"
          >
          </datetime>
          <div
            class="icon-container text-right"
            @click="$refs.transferDateEnd.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.valid_to.$error">
          <span class="text-error">Please input start date.</span>
        </div>
      </b-col>
    </b-row>
    <div class="mt-3">
      <b-form-checkbox
        switch
        v-model="form.active"
        class="radio-active"
        size="lg"
      >
        <span class="ml-2 main-label">{{
          form.active ? "Active" : "Inactive"
        }}</span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    eventList: {
      required: true,
      type: Array,
    },
    campaignList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      hourMinute: "",
    };
  },
  created() {
    if (this.form.round_hours) {
      let hour = "";
      let minute = "";
      if (this.form.round_hours.toString().length > 1) {
        hour = this.form.round_hours;
      } else {
        hour = "0" + this.form.round_hours;
      }
      if (this.form.round_minutes.toString().length > 1) {
        minute = this.form.round_minutes;
      } else {
        minute = "0" + this.form.round_minutes;
      }
      this.hourMinute = hour + ":" + minute;
    }
  },
  watch: {
    hourMinute(val) {
      const getHour = new Date(val);
      const hour = getHour.getHours();
      const minute = getHour.getMinutes();
      if (hour.toString().length > 1) {
        this.form.round_hours = hour;
      } else {
        this.form.round_hours = "0" + hour;
      }
      if (minute.toString().length > 1) {
        this.form.round_minutes = minute;
      } else {
        this.form.round_minutes = "0" + minute;
      }
    },
  },
  methods: {
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .date-picker .vdatetime-input {
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
.border-error {
  border-color: red !important;
  border: 1px solid red;
  border-radius: 5px;
}
::v-deep .vdatetime-popup {
  top: 60% !important;
}
.input-container {
  display: flex;
  padding: 2px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
</style>
