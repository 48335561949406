var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white px-3 py-3"},[_c('b-row',[_c('b-col',[_c('InputText',{attrs:{"textFloat":"Name","placeholder":"Name","type":"text","name":"name","isRequired":"","v":_vm.v.form.name,"isValidate":_vm.v.form.name.$error},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-col',[_c('label',{staticClass:"label"},[_vm._v("Event Type"),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('b-form-select',{model:{value:(_vm.form.event_type_id),callback:function ($$v) {_vm.$set(_vm.form, "event_type_id", $$v)},expression:"form.event_type_id"}},_vm._l((_vm.eventList),function(event,index){return _c('option',{key:index,domProps:{"value":event.id}},[_vm._v(" "+_vm._s(event.name)+" ")])}),0)],1)],1),(_vm.form.event_type_id === 1 || _vm.form.event_type_id === 5)?_c('div',[_c('b-row',[_c('b-col',[_c('InputText',{attrs:{"textFloat":"Sending date","placeholder":"Sending date","type":"number","name":"round_day","oninput":'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 2);',"v":_vm.v.form.round_day,"isValidate":_vm.v.form.round_day.$error},model:{value:(_vm.form.round_day),callback:function ($$v) {_vm.$set(_vm.form, "round_day", $$v)},expression:"form.round_day"}})],1),_c('b-col',[_c('label',{staticClass:"label"},[_vm._v("Hours/Minutes")]),_c('div',{class:[
            _vm.v.form.round_hours.$error
              ? 'border-error input-container'
              : 'input-container',
          ]},[_c('datetime',{ref:"automationHourMinute",staticClass:"date-picker",attrs:{"type":"time","input-style":_vm.styleDatetime,"placeholder":"HH:MM","format":"HH:mm","value-zone":"Asia/Bangkok"},model:{value:(_vm.hourMinute),callback:function ($$v) {_vm.hourMinute=$$v},expression:"hourMinute"}}),_c('div',{class:'icon-container text-right',on:{"click":function($event){_vm.$refs.automationHourMinute.isOpen = true}}},[_c('font-awesome-icon',{class:'pointer color-primary',attrs:{"icon":"calendar-alt","color":"#B41BB4"}})],1)],1),(_vm.v.form.round_hours.$error)?_c('div',[_c('span',{staticClass:"text-error"},[_vm._v("Please select.")])]):_vm._e()])],1)],1):_vm._e(),(_vm.form.event_type_id === 7)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"label"},[_vm._v("Hours/Minutes")]),_c('div',{class:[
          _vm.v.form.round_hours.$error
            ? 'border-error input-container'
            : 'input-container',
        ]},[_c('datetime',{ref:"automationHourMinute",staticClass:"date-picker",attrs:{"type":"time","input-style":_vm.styleDatetime,"placeholder":"HH:MM","format":"HH:mm","value-zone":"Asia/Bangkok"},model:{value:(_vm.hourMinute),callback:function ($$v) {_vm.hourMinute=$$v},expression:"hourMinute"}}),_c('div',{class:'icon-container text-right',on:{"click":function($event){_vm.$refs.automationHourMinute.isOpen = true}}},[_c('font-awesome-icon',{class:'pointer color-primary',attrs:{"icon":"calendar-alt","color":"#B41BB4"}})],1)],1),(_vm.v.form.round_hours.$error)?_c('div',[_c('span',{staticClass:"text-error"},[_vm._v("Please select.")])]):_vm._e()])],1):_vm._e(),(_vm.form.event_type_id == 3)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"label"},[_vm._v("Campaign"),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('b-form-select',{class:_vm.v.form.campaign_id.$error ? 'border-error' : '',scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please Select")])]},proxy:true}],null,false,1268391998),model:{value:(_vm.form.campaign_id),callback:function ($$v) {_vm.$set(_vm.form, "campaign_id", $$v)},expression:"form.campaign_id"}},_vm._l((_vm.campaignList),function(campagin,c){return _c('option',{key:c,domProps:{"value":campagin.id}},[_vm._v(" "+_vm._s(campagin.name)+" ")])}),0),(_vm.v.form.campaign_id.$error)?_c('div',[_c('span',{staticClass:"text-error"},[_vm._v("Please select Campagin.")])]):_vm._e()],1)],1):_vm._e(),_c('hr'),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('label',{staticClass:"label"},[_vm._v("Start Date (DD/MM/YYYY) "),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('div',{class:_vm.v.form.valid_from.$error
            ? 'border-error input-container'
            : 'input-container'},[_c('datetime',{ref:"transferDateStart",class:'date-picker mb-0',attrs:{"type":"datetime","input-style":_vm.styleDatetime,"min-datetime":_vm.dateFormat(),"placeholder":"DD/MM/YYYY (HH:MM)","format":"dd/MM/yyyy (HH:mm)","value-zone":"Asia/Bangkok"},model:{value:(_vm.form.valid_from),callback:function ($$v) {_vm.$set(_vm.form, "valid_from", $$v)},expression:"form.valid_from"}}),_c('div',{class:'icon-container text-right',on:{"click":function($event){_vm.$refs.transferDateStart.isOpen = true}}},[_c('font-awesome-icon',{class:'pointer color-primary',attrs:{"icon":"calendar-alt","color":"#B41BB4"}})],1)],1),(_vm.v.form.valid_from.$error)?_c('div',[_c('span',{staticClass:"text-error"},[_vm._v("Please input start date.")])]):_vm._e()]),_c('b-col',[_c('label',{staticClass:"label"},[_vm._v("End Date (DD/MM/YYYY) "),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('div',{class:[
          _vm.v.form.valid_to.$error
            ? 'border-error input-container'
            : 'input-container',
        ]},[_c('datetime',{ref:"transferDateEnd",class:'date-picker mb-0',attrs:{"type":"datetime","input-style":_vm.styleDatetime,"placeholder":"DD/MM/YYYY (HH:MM)","format":"dd/MM/yyyy (HH:mm)","value-zone":"Asia/Bangkok","min-datetime":_vm.form.valid_from},model:{value:(_vm.form.valid_to),callback:function ($$v) {_vm.$set(_vm.form, "valid_to", $$v)},expression:"form.valid_to"}}),_c('div',{staticClass:"icon-container text-right",on:{"click":function($event){_vm.$refs.transferDateEnd.isOpen = true}}},[_c('font-awesome-icon',{class:'pointer color-primary',attrs:{"icon":"calendar-alt","color":"#B41BB4"}})],1)],1),(_vm.v.form.valid_to.$error)?_c('div',[_c('span',{staticClass:"text-error"},[_vm._v("Please input start date.")])]):_vm._e()])],1),_c('div',{staticClass:"mt-3"},[_c('b-form-checkbox',{staticClass:"radio-active",attrs:{"switch":"","size":"lg"},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}},[_c('span',{staticClass:"ml-2 main-label"},[_vm._v(_vm._s(_vm.form.active ? "Active" : "Inactive"))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }